<template>
  <div>
    <!-- 头部 -->
    <mHeader val="yes"></mHeader>
    <!-- 进度条 -->
    <Progress :val="1" type="mb"></Progress>

    <!-- 内容 -->
    <div class="neiron">
      <!-- 日历 -->
      <div class="calendar">
        <mCalendar @dateClick="dateClickFn"></mCalendar>
      </div>
      <!-- 数量 选择 -->
      <div class="select-num" v-for="(item, index) in priceList" :key="index">
        <div class="select-num-tit">
          <div>
            <p>
              {{
                item.type === 1
                  ? "Moominvalley Park 1-Day Pass"
                  : "Moominvalley Park  Night Pass (admission from 16:00)"
              }}
            </p>
            <div class="night_pass" v-if="item.type === 2">
              Night Pass will go on sale on November 1.
            </div>
          </div>
          <!-- 气泡弹出 -->
          <p @click="continueFn(item.type)" class="right">
            <SvgIcon
              iconClass="home_details"
              style="font-size: 16px; cursor: pointer; margin-right: 2px"
            ></SvgIcon>
            Details
          </p>
        </div>
        <!-- 内容 -->
        <div class="select-num-nier">
          <div class="item" v-if="item.adult">
            <div class="lft">
              <!-- <p>Adult (18+)</p> -->
              <p v-if="item.adult && item.adult.title">
                {{ item.adult.title }}
              </p>
              <span class="price" v-if="item.type === 1"
                >￥{{
                  adultMaxNum
                    ? setSumPrice(item.adult && item.adult.price, 1) || "-"
                    : "-"
                }}</span
              >
              <span class="price" v-else>
                ￥{{
                  nightadultMaxNum
                    ? setSumPrice(item.adult && item.adult.price, 1) || "-"
                    : "-"
                }}
              </span>
            </div>
            <div class="rght">
              <div class="Adultnum">
                <el-input-number
                  v-if="item.type === 1"
                  v-model="adultNum"
                  @change="handleChange"
                  :min="0"
                  :max="adultMaxNum"
                  label="描述文字"
                ></el-input-number>
                <el-input-number
                  v-else
                  v-model="nightadultNum"
                  @change="handleChange"
                  :min="0"
                  :max="nightadultMaxNum"
                  label="描述文字"
                ></el-input-number>
              </div>
            </div>
          </div>
          <div
            class="item"
            style="border-top: 1px solid #d5d7d9"
            v-if="item.student"
          >
            <div class="lft">
              <!-- <p>Secondary Student (13~17)</p> -->
              <p v-if="item.student && item.student.title">
                {{ item.student.title }}
              </p>
              <span class="price" v-if="item.type === 1"
                >￥{{
                  studentMaxNum
                    ? setSumPrice(item.student && item.student.price, 1) || "-"
                    : "-"
                }}</span
              >
              <span class="price" v-else
                >￥{{
                  nightstudentMaxNum
                    ? setSumPrice(item.student && item.student.price, 1) || "-"
                    : "-"
                }}</span
              >
            </div>
            <div class="rght">
              <div class="Adultnum">
                <el-input-number
                  v-if="item.type === 1"
                  v-model="studentNum"
                  @change="handleChange"
                  :min="0"
                  :max="studentMaxNum"
                  label="描述文字"
                ></el-input-number>
                <el-input-number
                  v-else
                  v-model="nightstudentNum"
                  @change="handleChange"
                  :min="0"
                  :max="nightstudentMaxNum"
                  label="描述文字"
                ></el-input-number>
              </div>
            </div>
          </div>
          <div
            class="item"
            v-if="item.child"
            style="border-top: 1px solid #d5d7d9"
          >
            <div class="lft">
              <!-- <p>Child (4~12)</p> -->
              <p v-if="item.child && item.child.title">
                {{ item.child.title }}
              </p>
              <span class="price" v-if="item.type === 1"
                >￥{{
                  childMaxNum
                    ? setSumPrice(item.child && item.child.price, 1) || "-"
                    : "-"
                }}</span
              >
              <span class="price" v-else
                >￥{{
                  nightchildMaxNum
                    ? setSumPrice(item.child && item.child.price, 1) || "-"
                    : "-"
                }}</span
              >
            </div>
            <div class="rght">
              <div class="Childnum">
                <el-input-number
                  v-if="item.type === 1"
                  v-model="childNum"
                  @change="handleChange"
                  :min="0"
                  :max="childMaxNum"
                  label="描述文字"
                ></el-input-number>
                <el-input-number
                  v-else
                  v-model="nightchildNum"
                  @change="handleChange"
                  :min="0"
                  :max="nightchildMaxNum"
                  label="描述文字"
                ></el-input-number>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 底部按钮 -->
    <div class="btm-btn">
      <!-- 价格 -->
      <div class="price">
        <p>Total</p>
        <span>￥{{ setSumPrice(totalPrice, 1) || "-" }}</span>
      </div>
      <!-- 按钮 -->
      <el-button @click="yesClick" :disabled="!setSumPrice(totalPrice, 1)"
        >Continue</el-button
      >
    </div>

    <!-- 下一步 弹窗 -->
    <div class="nextDialog">
      <el-dialog
        :visible.sync="nextDialogBr"
        width="93vw"
        :before-close="handleClose"
      >
        <div class="title">{{ title }}</div>
        <div class="mainDlg">
          <p v-if="title == 'Moominvalley Park Night Pass'">
            • Entry is only allowed after 4 PM.
          </p>
          <p>
            • Refundable three days before the day of visit. For refund please
            email
            <a
              style="color: #007bac; white-space: nowrap"
              href="mailto:order@moominvalleypark-booking.com"
              >order@moominvalleypark-booking.com</a
            >
          </p>
          <p>
            • Re-entering the park on a valid day is allowed. Please show the
            verified ticket to the staff when re-entering.
          </p>
          <p>
            • When guests enter the Park, click the E-ticket URL and the
            E-ticket will be displayed on the page. Confirm your booking
            information for the E-ticket, then press the "Confirm" button, and
            show the verified ticket to the staff.
          </p>
          <p>
            • When purchasing more than one ticket, each guest has to show the
            verified ticket to the staff.
          </p>
          <p>
            • Reselling tickets or providing any ticket for the purpose of
            reselling to a third party is strictly prohibited whether it is for
            profit or not.
          </p>
          <p>
            • Tickets obtained by violating any of these prohibitions will be
            void. Refunds of those tickets are not allowed.
          </p>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button
            style="width: 100%"
            @click="nextDialogBr = false"
            type="primary"
            >OK</el-button
          >
        </span>
      </el-dialog>
    </div>

    <!-- cookies -->
    <div v-if="cookiesBuer != '0'" class="cookies">
      <div class="cookies-box">
        <div class="cookies-tit">Cookies will be used by the website</div>
        <div class="cookies-main">
          We use cookies, as well as third-party cookies, to improve the Site,
          evaluate our audience, enhance your experience.Please note that
          certain cookies we use are essential for the functioning of various
          parts of the website.
        </div>
        <el-button @click="cookiesBtn"> Accept </el-button>
      </div>
    </div>

    <!-- cookies 占位 -->

    <div v-if="cookiesBuer != '0'" class="placeholder-cookies">
      <div class="cookies-box">
        <div class="cookies-tit">Cookies will be used by the website</div>
        <div class="cookies-main">
          We use cookies, as well as third-party cookies, to improve the Site,
          evaluate our audience, enhance your experience.Please note that
          certain cookies we use are essential for the functioning of various
          parts of the website.
        </div>
        <el-button @click="cookiesBtn"> Accept </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import mHeader from "@/components/m_header.vue";
import Progress from "@/components/progress.vue";
import mCalendar from "@/components/m_calendar.vue";
import SvgIcon from "@/components/SvgIcon.vue";
import { get_price } from "@/api/common.js";
export default {
  data() {
    return {
      nextDialogBr: false, // 下一步弹窗状态
      childMaxNum: 0, // 最大预定数
      adultMaxNum: 0, // 最大预定数
      studentMaxNum: 0, // 最大预定数
      priceList: [], // 票数，价格
      totalPrice: "", // 总价
      cookiesBuer: localStorage.getItem("cookiesBuer") || "1", //
      adultNum: 0, // 票数量
      childNum: 0, // 票数量
      studentNum: 0, // 票数量
      nightadultNum: 0, //夜间票数量
      nightchildNum: 0, // 夜间票数量
      nightstudentNum: 0, // 夜间票数量
      param: {
        date: "",
        adult: "",
        adultPrice: "",
        child: "",
        childPrice: "",
        student: "",
        studentPrice: "",
        nightAdult: "",
        nightAdultPrice: "",
        nightChild: "",
        nightChildPrice: "",
        nightStudent: "",
        nightStudentPrice: "",
        totalPrice: "",
      },
      title: "",
    };
  },
  methods: {
    // 询问框确定按钮
    yesClick() {
      // console.log("yes");
      localStorage.setItem("orderParam", JSON.stringify(this.param));
      this.$router.push({
        path: "/m_info",
      });
    },
    handleClose(done) {
      done();
    },
    // 日历时间点击事件
    dateClickFn(date) {
      console.log(date);
      this.param.date = date;
      // 获取价格
      this.getPriceFn(date);
    },
    // 下一步
    continueFn(type) {
      // console.log(this.param);
      if (type === 1) {
        this.title = "Moominvalley Park 1-Day Pass";
      } else {
        this.title = "Moominvalley Park Night Pass";
      }
      this.nextDialogBr = true;
    },
    // 数量加减
    handleChange(value) {
      // console.log(value);
      // 计算总价
      let totalPrice = 0;
      let nighttotalPrice = 0;

      // 遍历 priceList 数组，计算各类型的总价
      this.priceList.forEach((priceData) => {
        if (priceData.type === 1) {
          totalPrice +=
            (priceData.child ? priceData.child.price : 0) * this.childNum;
          totalPrice +=
            (priceData.adult ? priceData.adult.price : 0) * this.adultNum;
          totalPrice +=
            (priceData.student ? priceData.student.price : 0) * this.studentNum;
        } else {
          // 添加夜间票价格计算
          nighttotalPrice +=
            (priceData.adult ? priceData.adult.price : 0) * this.nightadultNum;
          nighttotalPrice +=
            (priceData.child ? priceData.child.price : 0) * this.nightchildNum;
          nighttotalPrice +=
            (priceData.student ? priceData.student.price : 0) *
            this.nightstudentNum;
        }
      });

      this.totalPrice = totalPrice + nighttotalPrice;
      // console.log(this.totalPrice);

      this.param.adult = this.adultNum;
      this.param.child = this.childNum;
      this.param.student = this.studentNum;
      this.param.nightAdult = this.nightadultNum; // 更新夜间成人票
      this.param.nightChild = this.nightchildNum; // 更新夜间儿童票
      this.param.nightStudent = this.nightstudentNum; // 更新夜间学生票

      // this.param.adultPrice = this.priceList.adult.price;
      // this.param.childPrice = this.priceList.child.price;
      this.param.totalPrice = this.totalPrice;
    },
    // cookies点击事件
    cookiesBtn() {
      this.cookiesBuer = "0";
      localStorage.setItem("cookiesBuer", "0");
    },
    // 根据时间获取日期
    async getPriceFn(date) {
      let res = await get_price(date);
      this.priceList = res.data.data;
      console.log(this.priceList);

      // 没有库存操作
      this.param = {
        ...this.param,
        childPrice: null,
        adultPrice: null,
        studentPrice: null,
        nightAdult: null, // 初始化夜间成人票价格
        nightChild: null, // 初始化夜间儿童票价格
        nightStudent: null, // 初始化夜间学生票价格
      };

      // 初始化最大预定数量
      this.childMaxNum = 0;
      this.adultMaxNum = 0;
      this.studentMaxNum = 0;
      this.nightadultMaxNum = 0; // 初始化夜间成人票最大数量
      this.nightchildMaxNum = 0; // 初始化夜间儿童票最大数量
      this.nightstudentMaxNum = 0; // 初始化夜间学生票最大数量

      // 检查库存并设置价格和数量
      this.priceList.forEach((priceData) => {
        if (priceData.type === 1) {
          if (priceData.child && priceData.child.num > 0) {
            this.param.childPrice = priceData.child.price;
            this.childMaxNum = Math.min(priceData.child.num, 4);
            this.childNum = 0; // 设置默认值为最大可买数量
          }
          if (priceData.adult && priceData.adult.num > 0) {
            this.param.adultPrice = priceData.adult.price;
            this.adultMaxNum = Math.min(priceData.adult.num, 4);
            this.adultNum = 0; // 设置默认值为最大可买数量
          }
          if (priceData.student && priceData.student.num > 0) {
            this.param.studentPrice = priceData.student.price;
            this.studentMaxNum = Math.min(priceData.student.num, 4);
            this.studentNum = 0; // 设置默认值为最大可买数量
          }
        } else {
          if (priceData.child && priceData.child.num > 0) {
            this.param.nightChildPrice = priceData.child.price;
            this.nightchildMaxNum = Math.min(priceData.child.num, 4);
            this.nightchildNum = 0; // 设置默认值为最大可买数量
          }

          if (priceData.adult && priceData.adult.num > 0) {
            this.param.nightAdultPrice = priceData.adult.price;
            this.nightadultMaxNum = Math.min(priceData.adult.num, 4);
            this.nightadultNum = 0; // 设置默认值为最大可买数量
          }

          if (priceData.student && priceData.student.num > 0) {
            this.param.nightStudentPrice = priceData.student.price;
            this.nightstudentMaxNum = Math.min(priceData.student.num, 4);
            this.nightstudentNum = 0; // 设置默认值为最大可买数量
          }
        }
      });
      // 如果没有任何可购买的票，则清除数量
      if (
        !this.param.childPrice &&
        !this.param.adultPrice &&
        !this.param.studentPrice &&
        !this.param.nightChildPrice &&
        !this.param.nightAdultPrice &&
        !this.param.nightStudent
      ) {
        this.childNum = 0;
        this.adultNum = 0;
        this.studentNum = 0;
        this.nightadultNum = 0;
        this.nightchildMaxNum = 0;
        this.nightstudentMaxNum = 0;
      }

      this.handleChange();
    },
  },
  mounted() {
    if (this._isMobile()) {
      // 跳转至手机端路由
      if (this.$route.path != "/m_home") {
        this.$router.replace("/m_home");
      }
    } else {
      // 跳转至 pc 端路由
      if (this.$route.path != "/home") {
        this.$router.replace("/home");
      }
    }
  },
  components: {
    mHeader,
    Progress,
    mCalendar,
    SvgIcon,
  },
};
</script>

<style lang="less" scoped>
// 下一步弹窗
.nextDialog {
  /deep/.el-button + .el-button {
    margin-left: 20px;
  }
  /deep/.el-dialog__header {
    padding: 0;
  }
  /deep/.el-dialog__body {
    padding: 0;
    font-size: 14px;
    margin-bottom: 20px;
    color: #12334c;
    .title {
      word-break: break-word;
      font-size: 16px;
      margin-bottom: 20px;
      font-weight: bold;
    }
    .mainDlg {
      p {
        word-break: break-word;
        margin-bottom: 10px;
        font-size: 14px;
      }
    }
  }
  /deep/.el-dialog__footer {
    padding: 0;
  }
  /deep/.el-button {
    width: 159px;
    height: 42px;
    font-size: 16px;
  }
  /deep/.dialog-footer {
    // width: 348px;
    display: flex;
    // justify-content: space-between;
    justify-content: center;
  }

  /deep/.el-dialog {
    padding: 8vw 5.3333vw;
    border-radius: 12px;
    margin-top: 12vh !important;
  }
  /deep/.el-icon-close:before {
    content: "";
  }
  /deep/.el-button--default {
    border-radius: 7px;
    border: 1px solid #12334c;
    color: #12334c;
  }
  /deep/.el-button--primary {
    border-radius: 7px;
    border: 1px solid #12334c;
    color: #fff;
    background-color: #12334c;
  }
}
.neiron {
  padding: 0 16px;
  .calendar {
    // height: 395px;
    background-color: #fff;
    margin-top: 16px;
    border-radius: 12px;
    padding: 7.4667vw 4.2667vw;
  }

  .select-num {
    padding: 5.3333vw;
    padding-bottom: 0;
    background-color: #fff;
    border-radius: 12px;
    margin-top: 16px;

    .select-num-tit {
      margin-bottom: 2.6667vw;
      font-size: 4.2667vw;
      color: #12334c;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .night_pass {
        font-weight: 700;
        font-size: 3.8889vw;
        text-align: left;
        color: #fc2a22;
      }
      .right {
        display: flex;
        align-items: center;
      }
    }

    .select-num-nier {
      color: #12334c;
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 21.3333vw;
        .lft {
          font-size: 3.7333vw;
          p {
            font-weight: 500;
          }
        }
      }
    }
  }
}
// 底部按钮
.btm-btn {
  padding: 18px 16px 16px;
  margin-top: 16px;
  background-color: #fff;
  .price {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
    font-size: 5.3333vw;
    font-weight: bold;
    color: #12334c;

    p {
      font-weight: 600;
    }
  }
  .el-button {
    width: 100%;
    padding: 3.2vw 0;
    border-radius: 7px;
    background-color: #12334c;
    color: #fff;
    font-size: 4.2667vw;
  }
}

// 数量加减样式

/deep/.el-input-number {
  width: 37.8667vw;
  height: 10.6667vw;
  line-height: 10.6667vw;
}
/deep/.el-input-number .el-input__inner {
  padding-left: 0px;
  padding-right: 0px;
  width: 10.6667vw;
  height: 10.9333vw;
  margin-left: 13.3333vw;
  border-radius: 7px;
  margin-top: 0.2667vw;
  font-size: 4.2667vw;
  color: unset;
}
/deep/.el-input-number__decrease,
/deep/.el-input-number__increase {
  width: 10.6667vw;
  height: 10.6667vw;
  border: 1px solid #12334c;
  border-radius: 7px;
  color: #12334c;
  font-size: 5.3333vw;
  background-color: #fff;
  .el-icon-minus,
  .el-icon-plus {
    font-weight: 700;
  }

  &.is-disabled {
    border: 1px solid #b2bac1;
    .el-icon-minus,
    .el-icon-plus {
      color: #b2bac1;
    }
  }
}

/deep/.el-input-number__decrease:hover:not(.is-disabled)
  ~ .el-input
  .el-input__inner:not(.is-disabled),
/deep/.el-input-number__increase:hover:not(.is-disabled)
  ~ .el-input
  .el-input__inner:not(.is-disabled) {
  border-color: #dcdfe6;
}

// cookies
.cookies {
  z-index: 999;
  position: fixed;
}
.placeholder-cookies {
  opacity: 0;
}
.cookies,
.placeholder-cookies {
  width: 100%;
  background-color: #12334c;
  bottom: 0;
  color: #fff;
  padding: 16px;
  .cookies-box {
    max-width: 1030px;
    margin: 0 auto;

    .cookies-tit {
      margin-bottom: 8px;
      font-weight: bold;
      font-size: 14px;
    }
    .cookies-main {
      margin-bottom: 8px;
      font-weight: 400;
      font-size: 12px;
    }
    .el-button {
      width: 92px;
      padding: 6px 0;
      border-radius: 7px;
      background: #fff;
      font-size: 12px;
      border: 0;
      font-weight: 700;
      color: #12334c;
    }
  }
}
</style>